.patient {
  flex-grow: 1;
  height: 100%;
  // overflow: auto;
  .gender-container {
    display: flex;
    padding: 20px;
    justify-content: center;

    .option {
      padding: 20px;
      border: 1px solid #c2c2c2;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      &:first-child {
        margin-right: 20px;
      }

      img {
        width: 80px;
        height: auto;
      }
    }
  }
  
  .simple-form {
    display: flex;
    flex-direction: column;

    p.title {
      font-size: 1.5rem;
      width: 100%;
      text-align: center;
      margin-top: 0;
    }

    p.italic {
      font-style: italic;
    }

    .MuiFormControl-root {
      margin-bottom: 20px;
    }

    .patient-age {
      font-size: 1.5rem;
      text-align: center;
    }

    .patient-birthday {
      display: flex;
      > div {
        flex-grow: 1;
      }
    }

    .no-arrows {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
  
      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }  
    }
  }
}

@media (max-width: 768px) {
  .patient {
    .simple-form {
      .patient-birthday {
        flex-direction: column;
      }
    }
  }
}