.sidebar {
  display: inline-flex;
  flex-direction: column;
  width: 155px;
  font-size: 1.1rem;

  > div {
    padding: 10px;
    text-transform: capitalize;
    border-left: 2px solid transparent;

    &.active {
      font-weight: bold;
      border-left: 2px solid #1ac98e;
    }
    
    &.visited {
      border-left: 2px solid #1ac98e;

      &:not(.active) {
        cursor: pointer;  
      }
      
    }

    &.disabled {
      color: #c2c2c2;
    }

    &.progress-mobile {
      display: none;
    }
  }
}

@media (max-width: 991px) {
  .sidebar {
    flex-direction: row;
    justify-content: space-between;
    width: 778px;
    align-items: center;

    > div.progress-mobile {
      display: block;
      width: 100px;
      min-width: 100px;
      max-width: 100px;
      margin-right: 10px;
      height: 4px;
      padding: 0px 0;
      position: relative;
      border-radius: 5px;
      border: 1px solid #1CC998;

      &:after {
        content: '\A';
        position: absolute;
        background: #1CC998;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0%;
      }

      &.introduction:after {
        width: 25%;
      }

      &.patient:after {
        width: 50%;
      }

      &.symptoms:after {
        width: 75%;
      }

      &.result:after {
        width: 100%;
      }
    }

    > div:not(.active):not(.progress-mobile) {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
  }
}