.header {
  background-color: white;
  padding: 10px 10px;
  border-bottom: 2px solid #1576d1;
  margin-bottom: -2px;
  display: flex;
  align-items: center;

  img {
    width: 50px;

    &:not(.pol-logo) {
      margin-right: 10px;
    }
  }

  .header-title {
    font-size: 1.2rem;
    color: black;
    font-weight: bold;
    line-height: 36px;
    flex-grow: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    align-items: center;

    .loading {
      position: absolute;
      margin-top: 30px;
    }

    .success-message {
      position: absolute;
      margin-top: 40px;
      color: #1ac98e;
      font-size: 0.75rem;
    }
  }
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 20px;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;    
  }
}