.modal-error {
  .modal-footer {
    display: flex;
    justify-content: space-between;

    .green-button {
      &:not(.Mui-disabled) {
        background-color: #1ac98e;
      }
    }
  }  
}