#loading-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  /* change if the mask should have another color then white */
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* makes sure it stays on top */
}

