.symptoms {
  width: 100%;
  height: 100%;

  .label-text-symptoms {
    font-size: 1.4rem;
    width: 100%;
    text-align: center;
    font-weight: 400;
    margin-top: 0;
    // margin-bottom: 0;
  }

  .info-explanation-icon {
    background-image: url('/images/info-icon.svg');
    padding-top: 0px;
    width: 0px;
    height: 0px;
    padding: 9px;
    background-repeat: no-repeat;
    background-position: center;
    // margin-left: 4px;
  }

  .button-container {
    min-width: auto;
  }

  .question-explanation-container {
    text-align: center;
    padding-top: 10px;
  }

  .question-button-container {
    font-size: 0.75rem;
    color: #94a4b3;

    .info-explanation-icon {
      margin-right: 10px;
      padding: 8px;
    }
  }

  .single-options {
    display: flex;
    justify-content: center;

    .single-option {
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid #c2c2c2;
      border-radius: 25px;
      cursor: pointer;

      img {
        width: 80px;
        height: auto;
      }

      &:first-child {
        margin-right: 20px;
      }

      &.selected-single-option {
        background-color: #e6e6e6;
      }
    }
  }
}

@media (max-width: 480px) {
  .symptoms {
    .MuiList-root {
      .MuiListItem-root {
        flex-direction: column;
      }
    }
  }
} 