.result {
  display: flex;
  height: auto;
  position: relative;

  &.loading-result {
    width: 100%;
    min-height: 250px;
  }

  .result-container {
    display: flex;
    flex-direction: row;
    // overflow: auto;
    height: auto;

    .result-status {
      background-repeat: no-repeat;
      padding: 20px 20px 20px 20px;
      height: auto;
      background-size: 65%;
      background-size: 85px;
      width: 85px;
      min-width: 85px;
      max-width: 85px;
      background-position: 20px 30px;

      &.no_risk, &.self_monitoring {
        background-image: url('/images/result_status/no_risk.svg');
        background-color: #1ac98e;
      }

      &.quarantine {
        background-image: url('/images/result_status/quarantine.svg');
        background-color: #fcc419;
      }
      &.isolation_call {
        background-image: url('/images/result_status/isolation_call.svg');
        background-color: #fcc419;
      }

      &.call_doctor {
        background-image: url('/images/result_status/call_doctor.svg');
        background-color: #fcc419;
      }

      &.isolation_ambulance {
        background-image: url('/images/result_status/isolation_ambulance.svg');
        background-color: #ff6a67;
      }
    }

    .result-summary {
      padding: 30px 20px;

      .result-serius-symptoms {
        ul li::before {
          content: "\2022";
          color: red;
          font-weight: bold;
          display: inline-block; 
          width: 1em;
          margin-left: -1em;
        }
      }

      .re-do-test {
        text-align: right;
        color: blue;
        font-size: 0.9rem;
        span {
          cursor: pointer;
        }
      }
    }
    
  }
}

@media (max-width: 480px) {
  .result {
    .result-container {
      flex-direction: column;

      .result-status {
        width: auto;
        height: 0;
        padding-top: 70px;
        padding-bottom: 60px;
        background-position-x: center;
        background-size: 80px;
        max-width: calc(100% - 40px);
        min-width: calc(100% - 40px);
      }
    }
  }
}
