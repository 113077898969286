html, body {
  height: 100vh;
  width: 100%;
  margin: 0;

  @media not all and (hover:hover) {
    height: var(--app-height);
  }
}

body {
  background-color: #F3F5F7;
  overscroll-behavior: contain;
}