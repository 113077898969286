.layout {
  // padding: 10px 50px;

  .layout-view-container {
    display: flex;
    justify-content: center;
    padding-top: 30px;

    .panel-container {
      // padding: 0 20px;
      // flex-grow: 1;
      
      width: 780px;
      max-width: 780px;
      // background-color: white;
      border-radius: 5px;
      overflow: auto;
      position: relative;

      &:not(.result) {
        background-color: white;
      }

      .panel-content {
        display: flex;
        align-items: center;
        height: 465px;
        // height: calc(100vh - 330px);
        padding: 20px 20px;
        background-color: white;
        overflow: auto;

        &:not(:first-child) {
          margin-top: 20px;
        }
        // > div {
          // height: 100%;
        // }
      }

      .what-do-content {
        display: flex;
        align-items: center;
        height: auto;
        padding: 30px 20px;
        background-color: white;
        margin-top: 15px;
        box-shadow: 0 1px 3px 0 rgba(148,164,179,0.2);

        .whatToDo {
          flex-grow: 1;

          h3 {
            padding-left: 20px;
          }
        }
      }

      .result-disclaimer {
        display: flex;
        align-items: center;
        height: auto;
        padding: 30px;
        background-color: white;
        margin-top: 15px;
        color: #94a4b3;
        box-shadow: 0 1px 3px 0 rgba(148,164,179,0.2);
      }

      .footer-content {
        display: flex;
        justify-content: space-between;
        padding: 15px 20px;
        border-top: 1px solid #E7EBEE;

        // &.introduction-page {
        //   justify-content: flex-end;
        // }

        .green-button {
          &:not(.Mui-disabled) {
            background-color: #1ac98e;
          }
        }
      }

      .arrow-option {
        position: absolute;
        cursor: pointer;
        width: 20px;
        height: 20px;
        right: 30px;
        bottom: 90px;
        background-color: white;
        z-index: 1;
        // display: flex;
        justify-content: center;
        text-align: center;
        color: #ef7751;
        font-weight: bold;
        border-radius: 10px;
        border: 1px solid #c2c2c2;
        box-shadow: 1px 1px #c2c2c2;
        display: none;

        &.visible {
          display: flex;
        }
      }

      &.introduction {
        .footer-content {
          justify-content: flex-end;
        }
      }

      &.result {
        flex-direction: column;
        height: calc(100vh - 120px);
        overflow: auto;
        position: relative;

        .panel-content {
          padding: 0;
          height: auto;

          &.full-heigth {
            height: 100%;
          }
        }

        .footer-content  {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .layout {
    .layout-view-container {
      flex-direction: column;
      align-items: center;
      padding-top: 0;
      // .sidebar {
      //   > div:not(.active):not(.progress-mobile) {
      //     display: none;
      //   }
      // }
    }
  }
}

@media (max-width: 768px) {
  .layout { 
    .layout-view-container {
      padding-top: 0;
      .panel-container {
        width: 100%;
        max-width: 100%;

        &:not(.result) {
          .panel-content {
            // height: auto;
            height: calc(100vh - 290px);
            overflow: auto;
          }
        }

        &.result {
          height: calc(100vh - 170px);
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .layout { 
    .layout-view-container {
      padding-top: 30px 20px;
      .panel-container {
        &.result {

          .panel-content {
            padding: 30px 20px;
            // max-height: calc(100vh - 80px);
          }
        }
      }
    }
  }
}

